<template>
	<v-row>
		<v-col cols="12" md="12">
            <v-card>
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-title class="d-inline-block col-12 text-truncate">Venda realizada ano a ano</v-card-title>
                <v-card-text v-if="carregando">
                    <v-skeleton-loader class="mx-auto" width="100%" height="200" type="image, image"></v-skeleton-loader>
                </v-card-text>
                <v-card-text v-else class="pl-0">
                    <GraficoLineDashboard
                        v-if="dashboard.evolucaovendastotais"
                        :dataSeries="[
                                {
                                    name: 'Projeção',
                                    data: dashboard.evolucaovendastotais.filter(v=>v.ano != 2010).map(v => v.projecao )
                                },
                                {
                                    name: 'Realizado',
                                    data: dashboard.evolucaovendastotais.filter(v=>v.ano != 2010).map(v => v.realizado )
                                },
                            ]"
                        :categories="dashboard.evolucaovendastotais.filter(v=>v.ano != 2010).map(v => v.ano )"
                        height="200"
                        :money="true"
                        :porcent="false"
                    />
                    <span v-else>Nenhum registro encontrado</span>
                </v-card-text>
            </v-card>
        </v-col>
		<v-col cols="12">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="col-12">
					<span class="text-truncate">Crescimento de vendas ano a ano</span>
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoLineColumnDashboard
						v-if="anos[0]"
						:dataSeries="[
							{
								name: 'Realizado Ano Anterior',
								type: 'column',
								data: dashboard.evolucaovendastotais.filter(u => u.ano > 2010 && u.ano < 2022).map(v => v.realizado)
							},
							{
								name: 'Realizado Ano Posterior',
								type: 'column',
								data: dashboard.evolucaovendastotais.filter(u => u.ano > 2011).map(v => v.realizado)
							},
							{
								name: 'Crescimento / Projeção',
								type: 'line',
								data: dashboard.evolucaovendastotais.filter(u => u.ano > 2011).map(v => v.perprojecao)
							},
						]"
						:labels="anos.filter(u => u.anoc != 0 && u.anoc != '2010/2011').map(v => v.anoc)"
						:titlesY="[
							{ show: false, min: 0},
							{ show: false, min: 0},
							{ show: false, min: 0, max: 230},
						]"
						:tooltipsY="[
							{ enabled: true, formatter: function (val, data) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
							{ enabled: true, formatter: function (val, data) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
							{ enabled: true, formatter: function (val, data) { return `${val.toFixed(2)}%`} },
						]"
						height="300"
						:porcentagem="false"
						:money="false"
                        :stroke="{width: 3}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
            <v-card class="primary" dark>
				<v-card-title>
					<v-row class="mb-n9">
						<v-col cols="12 mt-1" sm="5">Detalhamento Filial</v-col>
						<v-spacer/>
						<v-col cols="8" lg="3" md="4" sm="4" class="px-1">
							<v-autocomplete :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial" dense outlined :disabled="carregando" v-model="busca.idfiliais" @change="get2"/>
						</v-col>
						<v-col cols="4 pl-1" lg="2" md="3" sm="3">
							<v-autocomplete :items="ultimos10anos" item-value="ano" label="Ano" dense outlined :disabled="carregando" v-model="busca.ano" @change="get2"/>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider />
                <v-card-text class="pa-3" v-if="busca.ano2 && evolucaoVendas2.length >0">
                    <v-simple-table v-if="evolucaoVendas.length >0 && busca.ano && busca.idfiliais" light dense class="rounded-t-0">
                        <thead>
                            <tr class="grey lighten-1">
                                <th class="text-left text-truncate">Mês</th>
                                <th class="text-left text-truncate">Meta</th>
                                <th class="text-left text-truncate">Realizado</th>
                                <th class="text-center text-truncate">Atingimento</th>
                                <th class="text-center text-truncate">Margem</th>
                                <th class="text-center text-truncate pl-0">Var.% Mês Ant.</th>
                                <th class="text-center text-truncate pl-0">Var.% Ano Ant.</th>
                                <th class="text-center text-truncate pl-0">Var.% AC Ano Ant.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(t, i) in evolucaoVendas" :key="i">
                                <td class="text-left text-truncate"><strong>{{t.mesname}}</strong></td>
                                <td class="text-left text-truncate">
                                    {{t.meta}}<br />
                                    <small><strong>AC: </strong>{{t.metaacumulada}}</small>
                                </td>
                                <td class="text-left text-truncate">
                                    {{t.realizado}}<br />
                                    <small><strong>AC: </strong>{{t.realizadoacumulado}}</small>
                                </td>
                                <td class="text-center text-truncate">
                                    {{t.atingimento}}<br />
                                    <small><strong>AC: </strong>{{t.atingimentoacumulado}}</small>
                                </td>
                                <td class="text-center text-truncate">{{t.margem}}%</td>
                                <td :class="t.variacaomesanterior < 0 ? 'red--text text-center text-truncate pl-0'  : 'text-center text-truncate pl-0' ">{{t.variacaomesanterior}}%</td>
                                <td :class="t.variacaomesanoanterior < 0 ? 'red--text text-center text-truncate pl-0'  : 'text-center text-truncate pl-0' ">{{t.variacaomesanoanterior}}%</td>
                                <td :class="t.variacaoacumuladames < 0 ? 'red--text text-center text-truncate pl-0'  : 'text-center text-truncate pl-0' ">{{t.variacaoacumuladames ? `${t.variacaoacumuladames.toFixed(2)}%` : '-'}}</td>
                            </tr>
                        </tbody>
                        <tfoot class="blue-grey lighten-4" v-if="dashboard2.evolucao_vendas_filial_totais != undefined">
                            <tr class="font-weight-bold">
                                <td class="text-left text-truncate">Total</td>
                                <td class="text-left text-truncate">{{dashboard2.evolucao_vendas_filial_totais.meta | formataDinheiro}}</td>
                                <td class="text-left text-truncate">{{dashboard2.evolucao_vendas_filial_totais.realizado | formataDinheiro}}</td>
                                <td class="text-center text-truncate">{{dashboard2.evolucao_vendas_filial_totais.atingimento ? `${dashboard2.evolucao_vendas_filial_totais.atingimento.toFixed(2)}%` : '-' }}</td>
                                <td class="text-center text-truncate">{{dashboard2.evolucao_vendas_filial_totais.margem.toFixed(2)}}%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-card-text>
                <v-card-text v-if="this.busca.idfiliais == null || this.busca.ano == null">
                    <v-alert type="error" elevation="0" icon="mdi-alert">Informe a filial e ano para buscar o detalhamento</v-alert>
                </v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card class="primary" dark>
				<v-card-title>
					<v-row class="mb-n9">
						<v-col cols="8 mt-1" sm="5">Detalhamento Grupo</v-col>
						<v-spacer/>
						<v-col cols="4 pl-1" lg="2" md="3" sm="3">
							<v-autocomplete :items="ultimos10anos" item-value="ano" label="Ano" dense outlined :disabled="carregando" v-model="busca.ano2" @change="get3"/>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider />
                <v-card-text class="pa-3" v-if="busca.ano2 && evolucaoVendas2.length >0">
				<v-simple-table light dense class="rounded-t-0">
					<thead>
						<tr class="grey lighten-1">
							<th class="text-left text-truncate">Mês</th>
							<th class="text-left text-truncate">Meta</th>
							<th class="text-left text-truncate">Realizado</th>
							<th class="text-center text-truncate">Atingimento</th>
							<th class="text-center text-truncate">Margem</th>
							<th class="text-center text-truncate pl-0">Var.% Mês Ant.</th>
							<th class="text-center text-truncate pl-0">Var.% Ano Ant.</th>
							<th class="text-center text-truncate pl-0">Var.% AC Ano Ant.</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(t,i) in evolucaoVendas2" :key="i">
                            <td class="text-left text-truncate"><strong>{{t.mesname}}</strong></td>
                            <td class="text-left text-truncate">
                                {{t.meta}}<br />
                                <small><strong>AC: </strong>{{t.metaacumulada}}</small>
                            </td>
                            <td class="text-left text-truncate">
                                {{t.realizado}}<br />
                                <small><strong>AC: </strong>{{t.realizadoacumulado}}</small>
                            </td>
                            <td class="text-center text-truncate">
                                {{t.atingimento}}<br />
                                <small><strong>AC: </strong>{{t.atingimentoacumulado}}</small>
                            </td>
							<td class="text-center text-truncate">{{t.margem}}</td>
                            <td :class="t.variacaomesanterior < 0 ? 'red--text text-center text-truncate pl-0'  : 'text-center text-truncate pl-0' ">{{t.variacaomesanterior}}%</td>
                            <td :class="t.variacaomesanoanterior < 0 ? 'red--text text-center text-truncate pl-0'  : 'text-center text-truncate pl-0' ">{{t.variacaomesanoanterior}}%</td>
                            <td :class="t.variacaoacumuladames < 0 ? 'red--text text-center text-truncate pl-0'  : 'text-center text-truncate pl-0' ">{{t.variacaoacumuladames.toFixed(2)}}%</td>
						</tr>
					</tbody>

					<tfoot class="blue-grey lighten-4" v-if="dashboard3.evolucao_vendas_filial_totais != undefined">
						<tr class="font-weight-bold">
                            <td dark class="text-left text-truncate">Total</td>
                            <td class="text-left text-truncate">{{dashboard3.evolucao_vendas_filial_totais.meta | formataDinheiro}}</td>
                            <td class="text-left text-truncate">{{dashboard3.evolucao_vendas_filial_totais.realizado | formataDinheiro}}</td>
                            <td class="text-center text-truncate">{{(dashboard3.evolucao_vendas_filial_totais.atingimento ? `${dashboard3.evolucao_vendas_filial_totais.atingimento.toFixed(2)}%` : '-')}}</td>
                            <td class="text-center text-truncate">{{(dashboard3.evolucao_vendas_filial_totais.margem ? `${dashboard3.evolucao_vendas_filial_totais.margem.toFixed(2)}%` : '-')}}</td>
                            <td></td>
							<td></td>
							<td></td>
						</tr>
					</tfoot>
				</v-simple-table>
                </v-card-text>
                <v-card-text v-if="this.busca.ano2 == null">
                    <v-alert type="error" elevation="0" icon="mdi-alert">Informe o ano para buscar o detalhamento</v-alert>
                </v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import GraficoLineDashboard from "../Widgets/GraficoLineDashboard";
import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";

export default {
	name: "DashboardEvolucao",
	mixins: [mixinFilial],
	components: { GraficoLineDashboard , GraficoLineColumnDashboard},
	data: () => ({
		mesAtual: new Date().getMonth() +1,
		busca: {
			idfiliais: 1,
			ano: new Date().getFullYear(),
			ano2: new Date().getFullYear(),
		},
		dashboard: {},
		dashboard2: {},
        dashboard3: {},
		carregando: false,
		evolucaoVendas : [],
        evolucaoVendas2 : [],
		anos: [],
		meses:[
			{ mes: 1, mesnome: 'Janeiro' },
			{ mes: 2, mesnome: 'Fevereiro' },
			{ mes: 3, mesnome: 'Março' },
			{ mes: 4, mesnome: 'Abril' },
			{ mes: 5, mesnome: 'Maio' },
			{ mes: 6, mesnome: 'Junho' },
			{ mes: 7, mesnome: 'Julho' },
			{ mes: 8, mesnome: 'Agosto' },
			{ mes: 9, mesnome: 'Setembro' },
			{ mes: 10, mesnome: 'Outubro' },
			{ mes: 11, mesnome: 'Novembro' },
			{ mes: 12, mesnome: 'Dezembro' }
		]
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
        ultimos10anos() {
            let r = []
            r[0] = new Date().getFullYear()
            for (let i = 1 ; i < 10 ; i++) {
                r[i] = r[i-1] - 1
            }
            return r
        }
	},
	methods: {
		listarAnos(){
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/evolucao/ano`, {})
				.then((res) => {
					this.anos = res.data;
					this.carregando = false;
					this.anos.forEach((v, i) => {
                        //console.log(this.dashboard.evolucaovendastotais.filter(u => u.ano > 2011).map(v => v.perprojecao).toFixed(2))
						if (i != 0 ){
                            v.anoc = v.anoc = this.anos[i - 1].ano + '/' + this.anos[i].ano
						} else {
							v.anoc = 0
						}
					});
				});
		},
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/evolucao`, {})
				.then((res) => {
					this.dashboard = res.data;
					this.carregando = false;
					this.dashboard.evolucaovendastotais.forEach((v, i) => {
						if (i == 0 ){
							v.crescimentoAno = (0)
						} else {
							v.crescimentoAno = (((this.dashboard.evolucaovendastotais[i].realizado/this.dashboard.evolucaovendastotais[i-1].realizado -1)*100)) //+ this.dashboard.evolucaovendastotais[i-1].crescimentoAno
						}
						if (v.ano == 2022){
							v.projecao = (v.realizado / (this.mesAtual-1)) * 12
							v.perprojecao = (((v.projecao/this.dashboard.evolucaovendastotais[i-1].realizado -1)*100))
						}else{
							v.perprojecao = v.crescimentoAno
							v.projecao = v.realizado
						}
					});
				});
		},
		get2() {
			this.dashboard2 = {};
			this.carregando = true;
            if (this.busca.idfiliais == null || this.busca.ano == null) {
                this.carregando = false
                return
            }
			return axios.post(`${this.backendUrl}dashboard/evolucao/filial`, {
                idfilial: this.busca.idfiliais || null,
                ano: this.busca.ano || null
            }).then((res) => {
                this.evolucaoVendas = res.data.evolucaovendasfilial.map((v, i) => {
                    // Cálculo de meta acumulada e realizado acumulado
                    let mAcumulada = 0
                    let realizadoAcumulado = 0
                    for (let index = 0; index <= i; index++) {
                        mAcumulada += res.data.evolucaovendasfilial[index].meta
                        realizadoAcumulado += res.data.evolucaovendasfilial[index].realizado
                    }

                    return {
                        ...v,
                        atingimento : v.atingimento > 0 ? `${v.atingimento.toFixed(2)}%` : '-',
                        metaacumulada          : mAcumulada,
                        realizadoacumulado     : realizadoAcumulado,
                        atingimentoacumulado   : mAcumulada > 0 ? `${((realizadoAcumulado / mAcumulada) * 100).toFixed(2)}%` : '-',
                        variacaomesanterior    : v.realizado > 0 && v.realizadomesanterior ? ((v.realizado/v.realizadomesanterior-1)*100).toFixed(2) : '-',
                        variacaomesanoanterior : v.realizadomesanoanterior > 0 ? ((v.realizado/v.realizadomesanoanterior-1)*100).toFixed(2) : '-'
                    }
                }).map((v) => {
                    return {
                        ...v,
                        meta : this.formataDinheiro(v.meta),
                        realizado : this.formataDinheiro(v.realizado),
                        metaacumulada : this.formataDinheiro(v.metaacumulada),
                        realizadoacumulado : this.formataDinheiro(v.realizadoacumulado)
                    }
                })
                this.dashboard2 = res.data;
                this.carregando = false;
            });
		},
        get3() {
            this.dashboard3 = {};
            this.carregando = true;
            return axios.post(`${this.backendUrl}dashboard/evolucao/totalano`, {
                ano: this.busca.ano2 || 2022
            }).then((res) => {
                this.evolucaoVendas2 = res.data.evolucaovendastotaismes.map((v) => {
                    return {
                        ...v,
                        meta : this.formataDinheiro(v.meta),
                        metaacumulada : this.formataDinheiro(v.metaacumulada),
                        realizado : this.formataDinheiro(v.realizado),
                        realizadoacumulado : this.formataDinheiro(v.realizadoacumulado),
                        atingimento   :  v.atingimento > 0 ? `${v.atingimento.toFixed(2)}%` : '-',
                        atingimentoacumulado   : v.metaacumulada > 0 ? `${((v.realizadoacumulado / v.metaacumulada) * 100).toFixed(2)}%` : '-' ,
                        margem   : v.margem > 0 ? `${v.margem.toFixed(2)}%` : '-',
                        variacaomesanterior   : ((v.realizado/v.realizadomesanterior-1)*100).toFixed(2),
                        variacaomesanoanterior : ((v.realizado/v.realizadomesanoanterior-1)*100).toFixed(2)
                    }
                })
                // this.dashboard3 = res.data.evolucao_vendas_filial_totais.map((u) => {
                //     return {
                //         ...u,
                //         meta : this.formataDinheiro(u.meta),
                //         realizado : this.formataDinheiro(u.realizado),
                //         atingimento   : u.atingimento.toFixed(2),
                //         margem   : u.margem.toFixed(2),
                //     }
                // })
                //this.dashboard3 = res.data.evolucao_vendas_filial_totais;

                this.dashboard3 = res.data;

                // console.log(this.dashboard3)
                this.carregando = false;
            });
        },
		async init() {
			await this.get();
            await this.get2();
            await this.get3();
            await this.listarAnos();
		},
	},
	created() {
		this.init();
	}
};
</script>

<style>
.border-right {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>